import { Grid } from '@chakra-ui/layout';
import { Box, Flex } from '@chakra-ui/react';
import _ from 'lodash';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { createNewAlert, CreateNewAlertDto } from 'src/apis/alerts.api';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useUserLocation } from 'src/hoc/UserLocationContext/user-location.context';
import { useToast } from 'src/hooks/use-toast';
import { useEntityLocations } from 'src/hooks/useEntityLocations';
import { Alert } from 'src/models/Alerts.model';
import { DashboardSection } from 'src/models/DashboardSection.model';
import { buildAlertPageRoute } from 'src/routes/alert.route';

import useAlerts from '../alerts/hooks/useAlerts';
import { AlertMapSection } from './components/AlertMapSection';
import HomePageHeading from './components/HomePageHeading';
import IconSection from './components/IconSection';
import ImageSection from './components/ImageSection';
import ImageSliderSection from './components/ImageSliderSection';
import { NavigationLinkComponent } from './components/NavigartionLink';
import TextSection from './components/TextSection';
import {
  NavigationLinks,
  SOSNavigationLink,
} from './constants/navigation-link.constant';
import useDashboardSections from './hooks/useDashboardSections';

const renderDashboardSection = (dashboardSection: DashboardSection) => {
  const { type, id } = dashboardSection;
  switch (type) {
    default:
    case 'textLink':
      return <TextSection dashboardSection={dashboardSection} key={id} />;
    case 'imageSlider':
      return (
        <ImageSliderSection dashboardSection={dashboardSection} key={id} />
      );
    case 'imageLink':
      return <ImageSection dashboardSection={dashboardSection} key={id} />;
    case 'iconLink':
      return <IconSection dashboardSection={dashboardSection} key={id} />;
  }
};

const HomePagePresentation = () => {
  const { showToast } = useToast();
  const { push } = useHistory();

  const [dashboardSections] = useDashboardSections();

  const { currentUser } = useUserDetails();
  const { locationStream, getIsLocationPermissionGranted } = useUserLocation();
  const { allAlerts } = useAlerts();
  const { allEntityLocations } = useEntityLocations();

  const newAlertMutation = useMutation((newAlert: CreateNewAlertDto) =>
    createNewAlert(newAlert),
  );

  const handleOnEmergencySosClick = async () => {
    const isLocationPermissionGranted = await getIsLocationPermissionGranted();
    if (!isLocationPermissionGranted) {
      showToast({
        title: 'SOS alert raised without location',
        status: 'warning',
      });
    }
    newAlertMutation.mutate(
      {
        RaisedBy: currentUser?.id,
        latitude: locationStream?.latitude?.toString() || null,
        longitude: locationStream?.longitude?.toString() || null,
      },
      {
        onSuccess: (alertInfo: Alert) => {
          if (alertInfo?.alertMessageThread?.MessageThreadId) {
            push(
              buildAlertPageRoute(
                alertInfo.id,
                alertInfo.alertMessageThread?.MessageThreadId,
              ),
            );
          }
        },
      },
    );
  };

  return (
    <Box w='full'>
      <Flex
        direction='column'
        gridGap={{ base: '4', sm: '6' }}
        w='full'
        h='full'
      >
        <HomePageHeading />
        <Box border='2px solid' borderColor='gray.400' rounded='md'>
          <AlertMapSection
            allAlerts={allAlerts}
            allEntityLocations={allEntityLocations}
          />
        </Box>
        <Grid templateColumns='repeat(2, 1fr)' gap='4'>
          <NavigationLinkComponent
            key={SOSNavigationLink.label}
            {...SOSNavigationLink}
            onClick={handleOnEmergencySosClick}
          />
          {_.map(NavigationLinks, link => (
            <NavigationLinkComponent key={link.label} {...link} />
          ))}
        </Grid>
      </Flex>
      <Grid
        gap={8}
        align='start'
        py={dashboardSections.length ? { base: '4', sm: '6' } : '0'}
      >
        {dashboardSections.map(renderDashboardSection)}
      </Grid>
    </Box>
  );
};

export default HomePagePresentation;
