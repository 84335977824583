import {
  Checkbox,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { FaFilter } from 'react-icons/fa';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

import { MapFilter } from '../../types/common.types';

interface MapFilterButtonProps {
  setMapFilter: Dispatch<SetStateAction<MapFilter>>;
  isFullScreen?: boolean;
}

export const MapFilterButton: FC<MapFilterButtonProps> = ({
  setMapFilter,
  isFullScreen = false,
}) => {
  const { translate } = useTranslate();

  const onFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMapFilter(prev => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }));
  };

  return (
    <Menu isLazy placement='left-start'>
      <MenuButton
        rounded='md'
        position='absolute'
        right={isFullScreen ? '6' : '4'}
        bottom={isFullScreen ? '12' : '16'}
        //zIndex is 1000 because the tile layer of Map container in leaflet has a fixed z-Index value of 800
        zIndex='1000'
        as={IconButton}
        aria-label='Options'
        icon={<FaFilter />}
        border='2px solid rgba(0,0,0,0.2)'
        _focus={{}}
        bg='white'
      />

      <MenuList zIndex='1001'>
        <MenuItem>
          <Checkbox defaultChecked onChange={onFilterChange} name='ownPosition'>
            {translate(TranslationKeys.ownLocation)}
          </Checkbox>
        </MenuItem>
        <MenuItem>
          <Checkbox
            defaultChecked
            onChange={onFilterChange}
            name='alertsPosition'
          >
            {translate(TranslationKeys.alertsLocation)}
          </Checkbox>
        </MenuItem>
        <MenuItem>
          <Checkbox
            defaultChecked
            onChange={onFilterChange}
            name='usersPosition'
          >
            {translate(TranslationKeys.usersLocation)}
          </Checkbox>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
