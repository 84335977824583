import _ from 'lodash';
import { useQuery } from 'react-query';

import { getMessageThreadMembersLocation } from 'src/apis/message-thread-members.api';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { getUserFullName } from 'src/utils';

const kMessageThreadMembersLocationQueryKey = 'message-thread-members-location';

export const useMessageThreadMembersLocation = (messageThreadId: number) => {
  const { currentUser } = useUserDetails();
  const { data, refetch: refetchMembersLocation } = useQuery(
    kMessageThreadMembersLocationQueryKey,
    () => getMessageThreadMembersLocation(messageThreadId),
    {
      enabled: !!messageThreadId,
    },
  );

  return {
    threadMembersLocation:
      _.values(data).filter(
        member => member.userName !== getUserFullName(currentUser),
      ) ?? {},
    refetchMembersLocation,
  };
};
