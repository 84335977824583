import { useQuery } from 'react-query';

import { getAlertMembers } from 'src/apis/alert-members.api';
import { REFETCH_CONFIG } from 'src/constants';
import { AlertGroupMembers } from 'src/models/alert-group-member';

const kAlertMembersQueryKey = 'alertMembers';

export const useAlertMembers = () => {
  const { data: allAlertMembers, ...queryMetadata } = useQuery<
    AlertGroupMembers[]
  >(kAlertMembersQueryKey, getAlertMembers, {
    ...REFETCH_CONFIG,
  });

  return { allAlertMembers, queryMetadata };
};
