import { Image } from '@chakra-ui/image';
import { Box, HStack } from '@chakra-ui/layout';
import { LayoutProps } from '@chakra-ui/styled-system';
import ReactPlayer from 'react-player';

import { isAudio, isImage, isVideo } from '../../../utils/mime-type.utils';
import { PostMedia } from '../../../models/PostMedia.model';
import MediaPreview from '../../../components/MediaPreview';
import DownloadButton from '../../../components/DownloadButton';
import PostImagePreviewDialog from './PostImagePreviewDialog';
import useDialog from '../../../hooks/useDialog';

interface PostMediaPreviewProps {
  media: PostMedia;
}

const PostMediaPreview: React.FC<PostMediaPreviewProps> = ({ media }) => {
  const mediaUrl = media.mediaUrl;
  const mediaMimeType = media.type;
  const commonProps: LayoutProps = {
    maxH: '52',
    minH: '24',
    w: 'full',
    maxW: 'full',
    objectFit: 'cover',
  };

  const [isPostImageDialogOpen, openPostImageDialog, closePostImageDialog] =
    useDialog();

  if (isImage(mediaMimeType)) {
    return (
      <>
        <Image
          {...commonProps}
          key={media.id}
          src={mediaUrl}
          rounded='sm'
          cursor='pointer'
          onClick={openPostImageDialog}
        />
        <PostImagePreviewDialog
          isOpen={isPostImageDialogOpen}
          onClose={closePostImageDialog}
          media={media}
        />
      </>
    );
  }

  if (isVideo(mediaMimeType)) {
    return <Box as={ReactPlayer} url={mediaUrl} {...commonProps} controls />;
  }

  if (isAudio(mediaMimeType)) {
    return (
      <audio style={{ width: '100%' }} controls playsInline>
        <source src={media.mediaUrl} type={media.type} />
      </audio>
    );
  }

  return (
    <HStack {...commonProps} h='52' bg='gray.400' justifyContent='center'>
      <MediaPreview media={{ mimeType: mediaMimeType, mediaUrl }} />
      <DownloadButton
        file={{ type: mediaMimeType, url: mediaUrl }}
        bg='white'
      />
    </HStack>
  );
};

export default PostMediaPreview;
