import { useToast, UseToastOptions } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import {
  createPersonalMessageThread,
  getOnePersonalMessageThreadWithUser,
} from 'src/apis/personal-message-threads.api';
import { REFETCH_CONFIG } from 'src/constants';
import { TranslationKeys } from 'src/constants/translation-keys';
import { CreatePersonalMessageThreadDto } from 'src/dto/personal-messageThreads.dto';
import { HttpStatus } from 'src/hoc/constants/HttpStatus';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { PersonalMessageThread } from 'src/models/PersonalMessageThread.model';
import {
  CHAT_PAGE_ROUTE,
  kMessageThreadIdParamName,
} from 'src/routes/routeList';
import { useTranslate } from './useTranslate';

export const useChat = (userId: number, afterChatStart?: () => void) => {
  const { translate } = useTranslate();
  const history = useHistory();
  const showToast = useToast();
  const [shouldSearchChat, setShouldSearchChat] = useState(false);
  const { currentUser } = useUserDetails();

  const errorToastOpts: UseToastOptions = {
    title: translate(TranslationKeys.unknownErrorOccur),
    description: translate(TranslationKeys.pleaseTryAfterSometime),
    status: 'error',
  };

  useQuery<PersonalMessageThread | null, AxiosError>(
    'getPersonalChatWithUser',
    () => getOnePersonalMessageThreadWithUser(+userId),
    {
      onSuccess: searchedChatWithUser => {
        if (searchedChatWithUser) {
          pushChatPage(searchedChatWithUser.id);
        }
      },
      onError: error => {
        if (error?.response?.status === HttpStatus.NOT_FOUND) {
          createChatWithUser({ recipient: +userId });
        } else {
          showToast(errorToastOpts);
        }
      },
      enabled: shouldSearchChat && !!currentUser,
      retry: (_, error) => {
        // Don't retry if we have a Unauthorized or NotFound error
        const statusCode = error.response && error.response.status;
        if (
          statusCode === HttpStatus.UNAUTHORIZED ||
          statusCode === HttpStatus.NOT_FOUND
        ) {
          return false;
        }

        return true;
      },
      ...REFETCH_CONFIG,
    },
  );

  const { mutate: createChatWithUser } = useMutation<
    any,
    any,
    CreatePersonalMessageThreadDto
  >(
    'createChatWithUser',
    () => createPersonalMessageThread({ recipient: +userId }),
    {
      onSuccess: createdChat => {
        if (createdChat) {
          pushChatPage(createdChat.id);
        }
      },
      onError: () => {
        showToast(errorToastOpts);
      },
    },
  );

  const pushChatPage = (messageThreadId: number) => {
    history.push(
      CHAT_PAGE_ROUTE.replace(
        `:${kMessageThreadIdParamName}`,
        messageThreadId.toString(),
      ),
    );
    afterChatStart?.();
  };

  const startChat = () => {
    setShouldSearchChat(true);
  };

  return {
    startChat,
  };
};
