import { useQuery } from 'react-query';

import { getAllEntityLocations } from 'src/apis/users.api';
import { EntityLocation } from 'src/dto/entity-location.dto';

const kEntityLocationsQueryKey = 'allEntityLocations';

export const useEntityLocations = () => {
  const { data: allEntityLocations, ...queryMetadata } = useQuery<
    EntityLocation[]
  >(kEntityLocationsQueryKey, getAllEntityLocations);

  return { allEntityLocations, queryMetadata };
};
