import { useEffect } from 'react';
import { useQueryLoaderContext } from 'src/hoc';

export const useDisableLoader = () => {
  const { setIsLoaderRequired } = useQueryLoaderContext();

  useEffect(() => {
    setIsLoaderRequired(false);

    return () => setIsLoaderRequired(true);
  }, []);
};
