import { useQuery } from 'react-query';
import { REFETCH_CONFIG } from 'src/constants';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { getFeedsForAuthenticated, getPublicFeeds } from '../../apis/feed.api';

const useFeeds = () => {
  const { currentUser } = useUserDetails();

  const { data: allFeeds, isLoading: isLoadingAllFeeds } = useQuery(
    'getAllFeeds',
    getFeedsForAuthenticated,
    {
      enabled: !!currentUser,
      ...REFETCH_CONFIG,
    },
  );

  const { data: publicFeeds, isLoading: isLoadingPublicFeeds } = useQuery(
    'getPublicFeeds',
    getPublicFeeds,
    {
      enabled: !currentUser,
      ...REFETCH_CONFIG,
    },
  );

  const isLoadingFeeds = isLoadingAllFeeds || isLoadingPublicFeeds;

  const feeds = currentUser ? allFeeds : publicFeeds;

  return { feeds, isLoadingFeeds };
};

export default useFeeds;
