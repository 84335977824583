import { axiosInstance } from 'src/config';
import { SOSMediaUpdateDto } from 'src/dto/SoS.dto';
import { Alert } from 'src/models/Alerts.model';
import { MessageType } from 'src/models/Message.model';
import { ThreadMembersLocation } from 'src/models/MessageThreadMember.model';
import { getRequestData } from './utils/get-request-data.util';

export interface CreateNewAlertDto {
  RaisedBy?: number;
  latitude?: string | number | null;
  longitude?: string | number | null;
}

export interface AlertMessageDto {
  messageThreadId: number;
  message: string;
  messageType: MessageType;
  alertId: number;
  latitude?: number;
  longitude?: number;
}

export const createNewAlert = (payload: CreateNewAlertDto) => {
  return getRequestData<Alert>(axiosInstance.post('/alerts', payload));
};

export const getAllAlerts = async (): Promise<Alert[]> => {
  const allAlerts = await getRequestData<Alert[]>(axiosInstance.get('/alerts'));
  return allAlerts.map<Alert>(alert => ({
    ...alert,
    createdAt: new Date(alert.createdAt),
  }));
};

export const getResolvedAlerts = async (): Promise<Alert[]> => {
  const allAlerts = await getRequestData<Alert[]>(
    axiosInstance.get('/alerts/resolved'),
  );
  return allAlerts.map<Alert>(alert => ({
    ...alert,
    createdAt: new Date(alert.createdAt),
  }));
};

export const updateAlertMediaApi = (dto: SOSMediaUpdateDto) => {
  const formData = new FormData();

  if (dto.alertId) {
    formData.append('alertId', dto.alertId);
  }
  if (dto.textContent) {
    formData.append('textContent', dto.textContent);
  }
  if (dto.caption) {
    formData.append('caption', dto.caption);
  }
  if (dto.messageMedia) {
    for (let file of dto.messageMedia) {
      formData.append('files', file);
    }
  }
  if (dto.latitude) {
    formData.append('latitude', dto.latitude);
  }
  if (dto.longitude) {
    formData.append('longitude', dto.longitude);
  }
  return { url: '/alerts/upload', formData };
};

export const resolveAlert = (
  alertId: number,
  latitude: string | undefined,
  longitude: string | undefined,
) => {
  return axiosInstance.put('/alerts/markAsResolved', {
    AlertId: alertId,
    latitude,
    longitude,
  });
};

export const getOneAlert = (alertId: string) => {
  return getRequestData(axiosInstance.get(`/alerts/${alertId}`));
};

export const createAlertMessage = (payload: AlertMessageDto) => {
  const messagePayload = {
    ...payload,
    messageAt: new Date().toISOString(),
  };
  return getRequestData(axiosInstance.post('/alerts/message', messagePayload));
};

export const getAlertMembersLocation = (alertId: number) => {
  const url = `alerts/${alertId}/members/location`;
  return getRequestData<ThreadMembersLocation>(axiosInstance.get(url));
};
