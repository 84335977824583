import {
  Grid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import {
  AlertInformation,
  AlertResolvedButton,
  AudioAlertMedia,
  ImageAlertMedia,
  VideoAlertMedia,
} from 'src/components';
import { AlertChatInput } from 'src/components/sos-alert/AlertChatInput';
import {
  CUSTOM_SCROLL_BAR_CSS,
  PAGE_HEIGHT_WITH_NAVBAR,
  PAGE_HEIGHT_WITH_NAVBAR_AND_FOOTER,
} from 'src/constants';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useQueryLoaderContext } from 'src/hoc';
import HeaderWrapper from 'src/hoc/HeaderWrapper';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useAlertMessage } from 'src/hooks/use-alert-message';
import { useToast } from 'src/hooks/use-toast';
import { useTranslate } from 'src/hooks/useTranslate';
import ChatMessagesPanel from 'src/pages/chat/components/ChatMessagesPanel';
import GroupMessageBox from 'src/pages/chat/components/GroupMessageBox';
import { AppNavigationType } from 'src/types/navigation.type';

import { AlertMapSection } from './AlertMapSection';
import { AlertResolvedBanner } from './AlertResolvedBanner';
import { AlertResolvedBy } from './AlertResolvedBy';
import { CustomAlertMapSection } from './CustomAlertMapSection';

export const AlertPage = () => {
  const { translate } = useTranslate();
  const { currentUser } = useUserDetails();
  const { showToast } = useToast();
  const { setIsLoaderRequired } = useQueryLoaderContext();

  const {
    messages,
    sendAlertMessage,
    handleKeyPress,
    handleOnTextInputChange,
    liveAlert,
    liveAlertMedia,
    refetch,
    setTextAudio,
    textAudio,
    alert,
    msgContent,
    alertMembersLocation,
  } = useAlertMessage();
  const [isMapVisible, setIsMapVisible] = useState(true);

  const sendMessage = () => {
    if (!currentUser) {
      showToast({
        title: translate(TranslationKeys.loginToReport),
        status: 'warning',
      });
      return;
    }
    sendAlertMessage(msgContent);
  };

  useEffect(() => {
    setIsLoaderRequired(false);

    return () => setIsLoaderRequired(true);
  }, []);

  if (!alert) {
    return null;
  }

  const alertMedia = liveAlertMedia ?? alert.alertMedias;
  const isResolved = (liveAlert?.ResolvedBy || alert.ResolvedBy) !== null;

  return (
    <HeaderWrapper
      pageTitle={translate(TranslationKeys.alertDetails)}
      navigationType={AppNavigationType.back}
      shouldHideFooter={true}
    >
      {isResolved && <AlertResolvedBanner />}
      <Grid
        templateRows={isResolved ? 'auto auto auto 2fr' : 'auto auto 3fr'}
        width='full'
        gap='4'
        height={
          isResolved
            ? PAGE_HEIGHT_WITH_NAVBAR
            : PAGE_HEIGHT_WITH_NAVBAR_AND_FOOTER
        }
      >
        <VStack
          pt='4'
          h='full'
          w='full'
          justify={isResolved ? 'center' : 'end'}
          lineHeight='none'
          position='relative'
        >
          <AlertInformation alert={alert} sendAlertMessage={sendAlertMessage} />
          <AlertResolvedButton
            label={TranslationKeys.done}
            alert={liveAlert ?? alert}
            refetchAlert={refetch}
          />
        </VStack>

        {isResolved && <AlertResolvedBy alert={liveAlert ?? alert} />}
        <CustomAlertMapSection
          height='full'
          title={translate(TranslationKeys.mapSectionAndMore)}
          setIsMapVisible={setIsMapVisible}
        >
          <Tabs w='full' h='full'>
            <TabList pr={{ base: '12', sm: '1' }} pl='1' overflowX='auto'>
              <Tab _focus={{}}>{translate(TranslationKeys.location)}</Tab>
              <Tab _focus={{}}>{translate(TranslationKeys.images)}</Tab>
              <Tab _focus={{}}>{translate(TranslationKeys.videos)}</Tab>
              <Tab _focus={{}}>{translate(TranslationKeys.audio)}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel h='30vh'>
                <AlertMapSection
                  alert={alert}
                  mapHeight='27vh'
                  threadMembersLocation={alertMembersLocation}
                />
              </TabPanel>
              <TabPanel h='30vh'>
                <ImageAlertMedia alertMedia={alertMedia} />
              </TabPanel>
              <TabPanel h='30vh'>
                <VideoAlertMedia alertMedia={alertMedia} />
              </TabPanel>
              <TabPanel h='30vh'>
                <AudioAlertMedia alertMedia={alertMedia} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </CustomAlertMapSection>
        <ChatMessagesPanel
          hasRecipientBlocked={false}
          messages={messages}
          MessageComponent={GroupMessageBox}
          textAudio={textAudio}
          setTextAudio={setTextAudio}
          hasAccepted={true}
          css={CUSTOM_SCROLL_BAR_CSS}
          borderTop={isMapVisible ? '1px solid' : 'none'}
          borderColor='gray.300'
          isAsRChat
        />
      </Grid>
      <AlertChatInput
        handleKeyPress={handleKeyPress}
        handleOnTextInputChange={handleOnTextInputChange}
        isResolved={isResolved}
        msgContent={msgContent}
        onClick={sendMessage}
      />
    </HeaderWrapper>
  );
};
