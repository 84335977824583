import { IconButton } from '@chakra-ui/button';
import { BoxProps, HStack, Text, VStack } from '@chakra-ui/layout';
import { Box, Flex } from '@chakra-ui/react';
import { useRef } from 'react';
import { FiDownload } from 'react-icons/fi';
import ReactPlayer from 'react-player';

import { Message } from '../../../../models/Message.model';
import { MessageMedia } from '../../../../models/MessageMedia.model';
import { downloadFile } from '../../../../utils/download-file.utils';
import { isAudio, isImage, isVideo } from '../../../../utils/mime-type.utils';
import MediaPreviewIcon from '../MediaPreviewIcon';
import ImageMedia from './ImageMedia';

interface MediaMessagePreviewProps extends BoxProps {
  messageMedia: MessageMedia;
}

export const MediaMessagePreview: React.FC<MediaMessagePreviewProps> = ({
  messageMedia,
  ...props
}) => {
  const { type: mediaMimeType, mediaUrl } = messageMedia;
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const handleOnDownloadClick = () =>
    downloadFile({ anchorRef, url: mediaUrl, mimeType: mediaMimeType });

  if (isImage(mediaMimeType)) {
    return (
      <ImageMedia mediaUrl={mediaUrl} mimeType={messageMedia.type} {...props} />
    );
  }

  if (isVideo(mediaMimeType)) {
    return (
      <video style={{ height: '25vh', width: '100%' }} controls playsInline>
        <source src={`${mediaUrl}#t=0.001`} type={mediaMimeType} />
      </video>
    );
  }

  if (isAudio(mediaMimeType)) {
    return (
      <Box width={{ base: '260px', sm: '350px' }}>
        <audio style={{ width: '100%' }} controls playsInline>
          <source src={mediaUrl} type={mediaMimeType} />
        </audio>
      </Box>
    );
  }

  const split = mediaUrl.split('/');
  const lastToken = split[split.length - 1];

  return (
    <HStack
      bg='white'
      p='4'
      maxW='72'
      width={{ base: '260px', sm: '350px' }}
      alignSelf='end'
      spacing='4'
      borderRadius='8'
      {...props}
      minW='full'
    >
      <MediaPreviewIcon mimeType={mediaMimeType} boxSize='6' />
      <Text
        flexGrow={1}
        textOverflow='ellipsis'
        whiteSpace='nowrap'
        overflow='hidden'
      >
        {lastToken}
      </Text>
      <IconButton
        as='a'
        aria-label='download file'
        isRound
        p='0'
        icon={<FiDownload />}
        onClick={handleOnDownloadClick}
      />
      <a href={mediaUrl} ref={anchorRef} style={{ display: 'none' }} download>
        .
      </a>
    </HStack>
  );
};

interface MediaMessageProps extends BoxProps {
  message: Message;
  mediaBoxProps?: BoxProps;
}

const MediaMessage: React.FC<MediaMessageProps> = ({
  message,
  mediaBoxProps,
  ...props
}) => {
  return (
    <VStack align='start' {...props}>
      {message.messageMedias.map(messageMedia => (
        <MediaMessagePreview
          key={messageMedia.id}
          messageMedia={messageMedia}
          {...mediaBoxProps}
        />
      ))}
      {message.text && <Text wordBreak='break-word'>{message.text}</Text>}
    </VStack>
  );
};

export default MediaMessage;
