export const NAVBAR_HEIGHT_PX = 50;
export const NAVBAR_HEIGHT = `${NAVBAR_HEIGHT_PX}px`;

export const FOOTER_HEIGHT_PX = 50;
export const FOOTER_HEIGHT = `${FOOTER_HEIGHT_PX}px`;

export const PAGE_HEIGHT_WITH_NAVBAR_AND_FOOTER = `calc(100svh - ${
  NAVBAR_HEIGHT_PX + FOOTER_HEIGHT_PX
}px)`;

export const PAGE_HEIGHT_WITH_NAVBAR = `calc(100svh - ${NAVBAR_HEIGHT_PX}px)`;

export const CUSTOM_SCROLL_BAR_CSS = {
  '&::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
    borderRadius: '16px',
    backgroundColor: '#F9FAFF',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#c1c1c1',
    borderRadius: '16px',
    width: '0px',
    height: '0px',
  },
};

export const CAMERA_FACING_MODE = {
  user: 'user',
  environment: 'environment',
};

export const NAVIGATION_ON_CALL_REJECT_DELAY_TIME_MS = 1000;

export const ONE_USER_IN_CALL = 1;

export const TWO_USER_IN_CALL = 2;

export const REACT_APP_VERSION = 'v5.0.2';

export const LacsColors = {
  transparent: 'transparent',
  gray: 'gray',
  blue: 'blue',
  red: 'red',
  green: 'green',
};

export const ColorShades = {
  100: 100,
  200: 200,
  300: 300,
  400: 400,
  500: 500,
  600: 600,
  700: 700,
  800: 800,
  900: 900,
};

export const DEVICE_LOCAL_LACS = 'l-lacs';
export const DEVICE_CLOUD_LACS = 'c-lacs';

export const REFETCH_CONFIG = {
  refetchIntervalInBackground: true,
  refetchInterval: 30000,
};
