import { useQuery } from 'react-query';
import { REFETCH_CONFIG } from 'src/constants';

import { getAllPersonalMessageThreads } from '../../../apis/personal-message-threads.api';
import { PersonalMessageThread } from '../../../models/PersonalMessageThread.model';
import useArrayDataQuery from '../../../utils/query-utils/useArrayDataQuery';

const kPersonalChatsQueryKey = 'getPersonalMessages';

export const usePersonalMessageQueryClient = () => {
  return useArrayDataQuery<PersonalMessageThread>({
    queryKey: kPersonalChatsQueryKey,
  });
};

const usePersonalMessages = () => {
  const { data, ...queryMetadata } = useQuery<PersonalMessageThread[]>(
    kPersonalChatsQueryKey,
    getAllPersonalMessageThreads,
    { ...REFETCH_CONFIG },
  );
  const personalMessageThreads = data || [];

  return { personalMessageThreads, queryMetadata };
};

export default usePersonalMessages;
