import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { addTextToSpeechEnabledState } from 'src/apis/messages.api';
import {
  TextAudioPayload,
  subscribeOnSpeech,
  unsubscribeOnSpeech,
  TextAudio,
} from 'src/apis/socket.io/messages.socket.io';
import { useHandsFreeOperationSetting } from 'src/hoc/HandsFreeOperationProvider';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useIntParam } from 'src/hooks/use-int-params';
import { MessageType } from 'src/models/Message.model';
import { ValueCallback } from 'src/types/common.type';

export const useASRChatAudio = (messageThread?: number) => {
  const { currentUser } = useUserDetails();

  const { messageThreadId = messageThread } = useIntParam<{
    messageThreadId: string;
  }>();
  const { setIsTextToSpeechEnable, isTextToSpeechEnable } =
    useHandsFreeOperationSetting();

  const [textAudio, setTextAudio] = useState<TextAudio>();

  const handleAudio = useCallback<ValueCallback<TextAudioPayload>>(
    newMessage => {
      if (newMessage.messageType !== MessageType.asrChat) {
        return;
      }
      if (_.includes(newMessage.userId, currentUser?.id)) {
        const audio = Buffer.from(newMessage.audio as string, 'base64');
        setTextAudio({ ...newMessage, audio });
      }
    },
    [currentUser],
  );

  useEffect(() => {
    if (isTextToSpeechEnable) {
      subscribeOnSpeech(handleAudio);
    }

    return () => {
      unsubscribeOnSpeech(handleAudio);
    };
  }, [isTextToSpeechEnable, handleAudio]);

  useEffect(() => {
    if (messageThreadId && currentUser) {
      addTextToSpeechEnabledState(messageThreadId, isTextToSpeechEnable);
    }
  }, [isTextToSpeechEnable, messageThreadId, currentUser]);

  useEffect(() => {
    setIsTextToSpeechEnable(true);
    return () => {
      setIsTextToSpeechEnable(false);
    };
  }, []);

  return { textAudio, setTextAudio };
};
