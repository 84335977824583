import * as Leaflet from 'leaflet';

import iconUrl from './orange-icon.svg';

export const OrangeMarker = new Leaflet.Icon({
  iconUrl,
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [30, 60],
});
