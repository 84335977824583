import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { QueryKey, useQuery } from 'react-query';
import { useHistory } from 'react-router';

import { getOneMessageThread } from 'src/apis/message-threads.api';
import {
  joinMessageThreadRoom,
  leaveMessageThreadRoom,
} from 'src/apis/socket.io/message-threads.socket.io';
import { REFETCH_CONFIG } from 'src/constants';
import { HttpStatus } from 'src/hoc/constants/HttpStatus';
import { useCustomQueryClient } from 'src/hoc/query-context';
import { MessageThread } from 'src/models/MessageThread.model';

const getMessageThreadQueryKey = (messageThreadId: number): QueryKey => [
  'getMessageThread',
  messageThreadId,
];

const useMessageThread = (messageThreadId: number) => {
  const history = useHistory();
  const { queryClient } = useCustomQueryClient();

  const { data: messageThread, ...queryMetadata } = useQuery(
    getMessageThreadQueryKey(messageThreadId),
    () => getOneMessageThread(messageThreadId),
    {
      onError: (err: AxiosError) => {
        if (err.response?.status === HttpStatus.NOT_FOUND) {
          history.goBack();
        }
      },
      enabled: !!messageThreadId,
      ...REFETCH_CONFIG,
    },
  );

  useEffect(() => {
    if (messageThread) {
      joinMessageThreadRoom(messageThread.id);
      return () => {
        leaveMessageThreadRoom(messageThread.id);
      };
    }
  }, [messageThread]);

  const updateMessageThread = (updatedData: MessageThread) => {
    if (!updatedData) return;

    queryClient.setQueryData<MessageThread>(
      getMessageThreadQueryKey(messageThreadId),
      prevData => ({ ...(prevData || {}), ...updatedData }),
    );
  };

  return {
    messageThread,
    updateMessageThread,
    ...queryMetadata,
  };
};

export default useMessageThread;
