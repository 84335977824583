import { Box, Text } from '@chakra-ui/react';
import _ from 'lodash';
import { useEffect } from 'react';
import { TileLayer, useMap } from 'react-leaflet';

import { FlyToUserLocationButton } from 'src/components/FlyToUserLocation/FlyToUserLocation';
import { LeafletMarker, MarkerColors } from 'src/components/Marker';
import { openStreetMapUrl } from 'src/config';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';
import { MessageThreadMemberLocation } from '../types/member-location.type';

interface MapContainerBodyProps {
  leafletMarkerPositions: MessageThreadMemberLocation[];
  mapZoom: number;
  mapCenter: L.LatLng;
}

export const MapContainerBodyContent: React.FC<MapContainerBodyProps> = ({
  leafletMarkerPositions,
  mapZoom,
  mapCenter,
}) => {
  const map = useMap();

  useEffect(() => {
    if (mapZoom) {
      map.setView(mapCenter, mapZoom);
    }
  }, [mapZoom]);

  return (
    <>
      <TileLayer url={openStreetMapUrl} />
      {leafletMarkerPositions &&
        _.map(leafletMarkerPositions, position => (
          <LeafletMarker
            markerColor={MarkerColors.orange}
            markerContent={<MarkerContent userName={position.userName} />}
            position={[
              _.toNumber(position.latitude),
              _.toNumber(position.longitude),
            ]}
            key={position.userName}
          />
        ))}

      <FlyToUserLocationButton />
    </>
  );
};

interface MarkerContentProps {
  userName: string;
}

const MarkerContent: React.FC<MarkerContentProps> = ({ userName }) => {
  const { translate } = useTranslate();
  return (
    <Box p='2'>
      <Text>
        {userName} {translate(TranslationKeys.position)}
      </Text>
    </Box>
  );
};
