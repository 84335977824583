import { Button, Grid } from '@chakra-ui/react';

import { CUSTOM_SCROLL_BAR_CSS, PAGE_HEIGHT_WITH_NAVBAR } from 'src/constants';
import { TranslationKeys } from 'src/constants/translation-keys';
import HeaderWrapper from 'src/hoc/HeaderWrapper';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useDisableLoader } from 'src/hooks/use-disable-loader';
import { useTranslate } from 'src/hooks/useTranslate';
import ChatMessagesPanel from 'src/pages/chat/components/ChatMessagesPanel';
import GroupMessageBox from 'src/pages/chat/components/GroupMessageBox';
import { AppNavigationType } from 'src/types/navigation.type';

import { AsrMapSection } from '../components/asr-map';
import { useAsrChatHistory } from '../hooks/use-asr-chat-history';
import { getPageTitleForASRChat } from '../utils';

export const AsrChatHistory: React.FC = () => {
  useDisableLoader();
  const { translate } = useTranslate();
  const { currentUser } = useUserDetails();
  const {
    messages,
    setTextAudio,
    textAudio,
    messageThread,
    startNewASRChat,
    threadMembersLocation,
  } = useAsrChatHistory();

  if (!currentUser || !messageThread) {
    return null;
  }

  return (
    <HeaderWrapper
      pageTitle={getPageTitleForASRChat(messageThread)}
      userSearchAllowed={false}
      navigationType={AppNavigationType.back}
      shouldHideFooter={true}
    >
      <Grid
        w='full'
        h={PAGE_HEIGHT_WITH_NAVBAR}
        templateRows='1fr 2fr 0.25fr'
        align='start'
        gap='4'
        px='2'
        py='4'
      >
        <AsrMapSection leafletMarkerPositions={threadMembersLocation} />
        <ChatMessagesPanel
          hasRecipientBlocked={false}
          messages={messages}
          MessageComponent={messageThread.isGroup ? GroupMessageBox : undefined}
          textAudio={textAudio}
          setTextAudio={setTextAudio}
          hasAccepted={true}
          css={CUSTOM_SCROLL_BAR_CSS}
        />
        <Button
          colorScheme='teal'
          w='max'
          justifySelf='center'
          alignSelf='center'
          px='6'
          onClick={() => startNewASRChat(messageThread.id)}
        >
          {translate(TranslationKeys.startASRChat)}
        </Button>
      </Grid>
    </HeaderWrapper>
  );
};
