import { Box } from '@chakra-ui/react';
import { useState } from 'react';

import { TextAudio } from 'src/apis/socket.io/messages.socket.io';
import Loader from 'src/components/loader/Loader';
import { NAVBAR_HEIGHT } from 'src/constants';
import { maxWidth } from 'src/constants/max-width.constant';
import { CHAT_PAGE_HEIGHT_WITH_NAVBAR_AND_FOOTER } from 'src/constants/message.constant';
import { useDisableLoader } from 'src/hooks/use-disable-loader';

import ChatFooter from './components/ChatFooter';
import ChatHeader from './components/ChatHeader';
import ChatMessagesPanel from './components/ChatMessagesPanel';
import GroupMessageBox from './components/GroupMessageBox';
import { MessageThreadStatus } from './components/MessageThreadStatus';
import { useChatPage } from './hooks/useChatPage';
import useMessageWithLimit from './hooks/useMessageWithLimit';

export interface tfModelType {
  model: string;
  metaData: string;
  binaryFile1: string;
  binaryFile2: string;
}

const ChatPage = () => {
  useDisableLoader();
  const [textAudio, setTextAudio] = useState<TextAudio>();

  const { message: msgContent, handleMessageChange: setMessage } =
    useMessageWithLimit();

  const {
    messageThread,
    typingText,
    handleUpdateAcceptance,
    hasAcceptedPersonalChat,
    hasRecipientBlocked,
    isSomeoneTyping,
    messageThreadId,
    messages,
    onMessageSend,
  } = useChatPage();

  if (!messageThread) {
    return <Loader />;
  }

  return (
    <Box m='0' overflow='hidden' position='relative' key={messageThreadId}>
      <ChatHeader messageThread={messageThread} setTextAudio={setTextAudio} />
      <Box
        borderLeft='1px solid'
        borderRight='1px solid'
        borderColor='gray.200'
        maxW={maxWidth}
        h={CHAT_PAGE_HEIGHT_WITH_NAVBAR_AND_FOOTER}
        mx='auto'
        mt={NAVBAR_HEIGHT}
        overflow='hidden'
        position='relative'
      >
        <ChatMessagesPanel
          hasRecipientBlocked={hasRecipientBlocked}
          messages={messages}
          MessageComponent={messageThread.isGroup ? GroupMessageBox : undefined}
          textAudio={textAudio}
          setTextAudio={setTextAudio}
          hasAccepted={hasAcceptedPersonalChat}
        />
        <MessageThreadStatus
          typing={isSomeoneTyping}
          typingText={typingText}
          isGroupChat={messageThread.isGroup}
          hasAccepted={hasAcceptedPersonalChat}
          onAcceptanceChange={handleUpdateAcceptance}
          hasRecipientBlocked={hasRecipientBlocked}
        />
      </Box>
      <ChatFooter
        chatInputPanelProps={{
          onMessageSend,
          messageThreadId,
          msgContent,
          setMessage,
        }}
      />
    </Box>
  );
};
export default ChatPage;
