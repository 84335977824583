import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import _ from 'lodash';
import { useContext } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useToggle } from 'react-use';

import { useCreatePost } from 'src/apis';
import { TranslationKeys } from 'src/constants/translation-keys';
import { UserLocationContext } from 'src/hoc/UserLocationContext';
import { useTranslate } from 'src/hooks/useTranslate';
import { NewPostType } from 'src/types/post.type';

import { useFeedPostsQueryClient } from './useFeedPosts';
import useFiles from './useFiles';

export const useAddFeedPost = (feedId: string, handleClose: () => void) => {
  const { translate } = useTranslate();
  const showToast = useToast();
  const { locationStream } = useContext(UserLocationContext);

  const [allowLocation, toggleAllowLocation] = useToggle(true);
  const { addFeedPost } = useFeedPostsQueryClient({ feedId: Number(feedId) });
  const { createPost, progress, setProgress } = useCreatePost();

  const addPostMutation = useMutation((newPost: NewPostType) =>
    createPost(newPost, feedId),
  );

  const {
    handleSubmit,
    register,
    reset: resetForm,
    control,
  } = useForm<NewPostType>({
    mode: 'onChange',
  });

  const {
    files: images,
    fileURIs: imageURIs,
    addFile: addImage,
    removeFile: removeImage,
    removeAllFiles: removeAllImages,
  } = useFiles();

  const {
    files: videos,
    fileURIs: videoURIs,
    addFile: addVideo,
    removeFile: removeVideo,
    removeAllFiles: removeAllVideos,
  } = useFiles();

  const {
    files: documentFiles,
    fileURIs: documentFileURIs,
    addFile: addDocumentFile,
    removeFile: removeDocumentFile,
    removeAllFiles: removeAllDocumentFiles,
  } = useFiles();

  const getAllFiles = (): File[] => _.concat(images, videos, documentFiles);

  const onClose = () => {
    resetForm();
    removeAllImages();
    removeAllVideos();
    removeAllDocumentFiles();
    handleClose();
  };

  const textContent = useWatch({
    name: 'textContent',
    control,
  });
  const hasFiles = !_.isEmpty(getAllFiles());
  const hasPostContent = hasFiles || textContent?.trim()?.length > 0;

  const formSubmitHandle = (data: NewPostType) => {
    addPostMutation.mutate(
      {
        ...data,
        files: getAllFiles(),
        latitude: allowLocation
          ? _.toString(locationStream?.latitude)
          : undefined,
        longitude: allowLocation
          ? _.toString(locationStream?.longitude)
          : undefined,
      },
      {
        onError: error => {
          showToast({
            title: translate(TranslationKeys.failedToCreatePost),
            description: (error as AxiosError).response?.data.message,
            status: 'error',
            isClosable: true,
          });
        },
        onSuccess: addedPost => {
          addFeedPost(addedPost);
          showToast({
            status: 'success',
            title: translate(TranslationKeys.newPostAdded),
          });
          setProgress(0);
          onClose();
        },
      },
    );
  };

  return {
    register,
    handleSubmit,
    addImage,
    removeImage,
    addVideo,
    removeVideo,
    addDocumentFile,
    removeDocumentFile,
    hasPostContent,
    allowLocation,
    toggleAllowLocation,
    imageURIs,
    videoURIs,
    documentFileURIs,
    formSubmitHandle,
    onClose,
    images,
    videos,
    documentFiles,
    isMutationLoading: addPostMutation.isLoading,
    uploadProgress: progress,
  };
};
