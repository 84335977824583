import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import {
  getAllCommentOfAuthenticatedPost,
  getAllCommentOfPublicPost,
} from 'src/apis/posts.api';
import { REFETCH_CONFIG } from 'src/constants';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';

export const usePostComments = () => {
  const { id } = useParams<{ id: string }>();
  const { postId } = useParams<{ postId: string }>();
  const { currentUser } = useUserDetails();

  const { data: publicComments, isLoading: isLoadingPublicComments } = useQuery(
    ['getAllCommentOfPublicPost', postId, id],
    () => getAllCommentOfPublicPost(+id, +postId),
    {
      enabled: !currentUser,
      ...REFETCH_CONFIG,
    },
  );

  const {
    data: authenticatedComments,
    isLoading: isLoadingAuthenticatedComments,
  } = useQuery(
    ['getAllAuthenticatedComments', postId, id],
    () => getAllCommentOfAuthenticatedPost(+id, +postId),
    { enabled: !!currentUser, ...REFETCH_CONFIG },
  );

  const comments = publicComments || authenticatedComments;
  const isLoading = isLoadingPublicComments || isLoadingAuthenticatedComments;

  return { comments, isLoading };
};
