import { useQuery } from 'react-query';

import { getAllEntityLocations } from 'src/apis/users.api';
import { EntityLocation } from 'src/dto/entity-location.dto';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';

const kEntityLocationsQueryKey = 'allEntityLocations';

export const useEntityLocations = () => {
  const { currentUser } = useUserDetails();

  const { data: allEntityLocations, ...queryMetadata } = useQuery<
    EntityLocation[]
  >(kEntityLocationsQueryKey, getAllEntityLocations, {
    enabled: !!currentUser,
  });

  return { allEntityLocations, queryMetadata };
};
