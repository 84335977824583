import _ from 'lodash';
import { useQuery } from 'react-query';

import { getAlertMembersLocation } from 'src/apis/alerts.api';

const kAlertMembersLocationQueryKey = 'alert-members-location';

export const useAlertMembersLocation = (alertId: number) => {
  const { data, refetch: refetchMembersLocation } = useQuery(
    kAlertMembersLocationQueryKey,
    () => getAlertMembersLocation(alertId),
    {
      enabled: !!alertId,
    },
  );

  return {
    alertMembersLocation: _.values(data) ?? {},
    refetchMembersLocation,
  };
};
