import { Button, Text, VStack, HStack, Box } from '@chakra-ui/react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import UserAvatar from 'src/components/UserAvatar';
import { SOS_ALERT_DATE_FORMAT } from 'src/constants/sos-alert.constants';
import { TranslationKeys } from 'src/constants/translation-keys';
import { EntityLocation } from 'src/dto/entity-location.dto';
import { useTranslate } from 'src/hooks/useTranslate';
import { User } from 'src/models';
import { Alert } from 'src/models/Alerts.model';
import { ALERT_PAGE_ROUTE, USER_PROFILE_ROUTE } from 'src/routes/routeList';
import { getUserFullName } from 'src/utils';
import { CallMessageIcon } from 'src/components/CallMessageIcon';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';

interface LeafMarkerContentProps {
  alert?: Alert;
  entityLocation?: EntityLocation;
}

export const LeafMarkerContent: React.FC<LeafMarkerContentProps> = ({
  alert,
  entityLocation,
}) => {
  const { translate } = useTranslate();
  const history = useHistory();
  const { currentUser } = useUserDetails();

  if (alert) {
    return (
      <VStack p='4'>
        <Text color='red' fontWeight='bold' fontSize='md'>
          {translate(TranslationKeys.emergencyAlert)}
        </Text>
        {alert.raisedBy ? (
          <Text fontWeight='bold'>
            {alert.raisedBy.firstName} {alert.raisedBy.lastName}
          </Text>
        ) : (
          <Text fontWeight='bold'>{translate(TranslationKeys.anonymous)}</Text>
        )}
        <Text>{moment(alert.createdAt).format(SOS_ALERT_DATE_FORMAT)}</Text>
        <Button
          colorScheme='blue'
          fontSize='xs'
          variant='link'
          onClick={() =>
            history.push(
              ALERT_PAGE_ROUTE.replace(':alertId', alert.id.toString()),
            )
          }
        >
          {`${translate(TranslationKeys.moreDetails)}...`}
        </Button>
      </VStack>
    );
  }

  if (entityLocation) {
    return (
      <VStack p='4'>
        <HStack w='full'>
          <UserAvatar user={entityLocation.user as User} />
          <Text fontWeight='bold' fontSize='md'>
            {getUserFullName(entityLocation.user as User)}
          </Text>
        </HStack>
        {currentUser && (
          <>
            <Box px='1'>
              <CallMessageIcon userId={entityLocation.EntityId} />
            </Box>
            <Button
              colorScheme='blue'
              fontSize='xs'
              variant='link'
              onClick={() =>
                history.push(
                  USER_PROFILE_ROUTE.replace(
                    ':userId',
                    entityLocation.EntityId.toString(),
                  ),
                )
              }
            >
              {`${translate(TranslationKeys.moreDetails)}...`}
            </Button>
          </>
        )}
      </VStack>
    );
  }

  return null;
};
