import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { REFETCH_CONFIG } from 'src/constants';

import { getAllGroupChats } from '../apis/group-message-threads.api';
import { GroupMessageThread } from '../models/GroupMessageThread.model';
import useArrayDataQuery from '../utils/query-utils/useArrayDataQuery';

const kGroupChatsQueryKey = 'getGroupMessages';

type UseGroupChatsReturn = [GroupMessageThread[], Omit<UseQueryResult, 'data'>];

const useGroupChats = (
  opts?: Pick<UseQueryOptions, 'enabled'>,
): UseGroupChatsReturn => {
  const { data, ...queryMetadata } = useQuery<GroupMessageThread[]>(
    kGroupChatsQueryKey,
    getAllGroupChats,
    { ...opts, ...REFETCH_CONFIG },
  );

  const groupChats = data || [];

  return [groupChats, queryMetadata];
};

export const useGroupChatsQueryClient = () => {
  return useArrayDataQuery<GroupMessageThread>({
    queryKey: kGroupChatsQueryKey,
  });
};

export default useGroupChats;
