import { Box, Text } from '@chakra-ui/react';
import _ from 'lodash';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { TileLayer, useMap } from 'react-leaflet';

import { FlyToUserLocationButton } from 'src/components/FlyToUserLocation/FlyToUserLocation';
import { LeafletMarker, MarkerColors } from 'src/components/Marker';
import { openStreetMapUrl } from 'src/config';
import { TranslationKeys } from 'src/constants/translation-keys';
import { EntityLocation } from 'src/dto/entity-location.dto';
import { useTranslate } from 'src/hooks/useTranslate';
import { Alert } from 'src/models/Alerts.model';
import { MessageThreadMemberLocation } from 'src/pages/asr-chat/types/member-location.type';

import { MapFilter } from '../../types/common.types';
import { LeafMarkerContent } from './leaflet-marker-content';
import { MapFilterButton } from './map-filter-button';

interface MapContainerBodyProps {
  alert?: Alert;
  allAlerts?: Alert[];
  allEntityLocations?: EntityLocation[];
  threadMembersLocation?: MessageThreadMemberLocation[];
  mapZoom: number;
  mapCenter: L.LatLng;
  setMapFilter: Dispatch<SetStateAction<MapFilter>>;
}

export const MapContainerBody: React.FC<MapContainerBodyProps> = ({
  alert,
  allAlerts,
  mapZoom,
  mapCenter,
  allEntityLocations,
  threadMembersLocation,
  setMapFilter,
}) => {
  const map = useMap();

  useEffect(() => {
    if (mapZoom) {
      map.setView(mapCenter, mapZoom);
    }
  }, [mapZoom]);

  return (
    <>
      <TileLayer url={openStreetMapUrl} />
      {allAlerts &&
        _.map(allAlerts, alert => {
          return (
            <LeafletMarker
              key={alert.id}
              markerColor={MarkerColors.red}
              markerContent={<LeafMarkerContent alert={alert} />}
              position={[
                _.toNumber(alert.latitude),
                _.toNumber(alert.longitude),
              ]}
            />
          );
        })}
      {allEntityLocations &&
        _.map(allEntityLocations, entityLocation => (
          <LeafletMarker
            key={entityLocation.id}
            markerColor={MarkerColors.orange}
            markerContent={
              <LeafMarkerContent entityLocation={entityLocation} />
            }
            position={[
              _.toNumber(entityLocation.latitude),
              _.toNumber(entityLocation.longitude),
            ]}
          />
        ))}
      {alert && (
        <LeafletMarker
          markerColor={MarkerColors.red}
          position={[_.toNumber(alert.latitude), _.toNumber(alert.longitude)]}
        />
      )}
      {threadMembersLocation &&
        _.map(threadMembersLocation, position => (
          <LeafletMarker
            markerColor={MarkerColors.orange}
            markerContent={<UserMarkerContent userName={position.userName} />}
            position={[
              _.toNumber(position.latitude),
              _.toNumber(position.longitude),
            ]}
            key={position.userName}
          />
        ))}
      <MapFilterButton setMapFilter={setMapFilter} />
      <FlyToUserLocationButton />
    </>
  );
};

interface MarkerContentProps {
  userName: string;
}

const UserMarkerContent: React.FC<MarkerContentProps> = ({ userName }) => {
  const { translate } = useTranslate();
  return (
    <Box p='2'>
      <Text>
        {userName} {translate(TranslationKeys.position)}
      </Text>
    </Box>
  );
};
