import { Center, CenterProps, IconButton, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

interface AlertMapSectionProps extends CenterProps {
  height: CenterProps['h'];
  title: string;
  setIsMapVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CustomAlertMapSection: React.FC<AlertMapSectionProps> = ({
  children,
  height,
  title,
  setIsMapVisible,
  ...props
}) => {
  const [showMap, setShowMap] = useState(true);

  useEffect(() => {
    setIsMapVisible && setIsMapVisible(showMap);
  }, [showMap]);

  return (
    <Center
      h={showMap ? height : '45px'}
      overflow='hidden'
      w='full'
      flexDir='column'
      position='relative'
      borderTop={showMap ? 'none' : '1px solid'}
      borderBottom={showMap ? 'none' : '1px solid'}
      borderColor='gray.300'
      {...props}
    >
      <IconButton
        alignSelf='flex-end'
        _focus={{}}
        borderColor='blackAlpha.800'
        bg='white'
        aria-label='toggle map'
        icon={showMap ? <FaAngleUp size='25' /> : <FaAngleDown size='25' />}
        position='absolute'
        zIndex='banner'
        onClick={() => setShowMap(prev => !prev)}
        top={showMap ? '1' : 'unset'}
        right='4'
        rounded='full'
        size='sm'
        variant='outline'
      />

      {showMap ? (
        children
      ) : (
        <Text fontWeight='semibold' textAlign='left' px='4' w='full'>
          {title}
        </Text>
      )}
    </Center>
  );
};
