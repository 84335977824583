import { Flex, LayoutProps, VStack } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalProps,
  ModalHeader,
} from '@chakra-ui/modal';
import { Image } from '@chakra-ui/image';

import { PostMedia } from '../../../models/PostMedia.model';
import DownloadButton from '../../../components/DownloadButton';
import CloseButton from 'src/components/CloseButton';

interface PostImagePreviewDialogProps extends Omit<ModalProps, 'children'> {
  media: PostMedia;
}

const PostImagePreviewDialog: React.FC<PostImagePreviewDialogProps> = ({
  media,
  ...props
}) => {
  const commonProps: LayoutProps = {
    maxH: 'full',
    minH: 'full',
    w: 'full',
    maxW: 'full',
    objectFit: 'cover',
  };

  return (
    <Modal {...props}>
      <ModalOverlay bg='blackAlpha.800' />
      <ModalContent maxH='80vh' bg='transparent'>
        <ModalHeader display='flex' justifyContent='end'>
          <DownloadButton file={{ url: media.mediaUrl, type: media.type }} />
          <Flex pl='2'>
            <CloseButton onClose={props.onClose} />
          </Flex>
        </ModalHeader>
        <ModalBody as={VStack} alignItems='center'>
          <Image {...commonProps} src={media.mediaUrl} rounded='sm' />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PostImagePreviewDialog;
