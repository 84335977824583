import { User } from 'src/models';

export const getUserFullName = (user: User | undefined) => {
  if (!user) return 'Anonymous';

  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }

  if (user.firstName) {
    return user.firstName;
  }

  return 'Anonymous';
};
